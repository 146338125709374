import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.$route.meta.keepAlive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 1
        })
      ]))
}