import { Ref, ref } from 'vue'
export function getCookie (name: string) {
  const strcookie = document.cookie // 获取cookie字符串
  const arrcookie = strcookie.split('; ') // 分割
  // 遍历匹配
  for (let i = 0; i < arrcookie.length; i++) {
    const arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arr[1]
    }
  }
  return ''
}
// 将数字格式化为金额格式
export const formatMoney = (number:any, decimals = 0, decPoint = '.', thousandsSep = ',') => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = []
  const toFixedFix = function (n:any, prec:any) {
    const k = Math.pow(10, prec)
    return '' + Math.ceil(n * k) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  const re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
}
// 当天日期
export const todayDate = () => {
  let todayDateValue = ''
  const appendZero = (obj: any) => {
    if (obj < 10) {
      return '0' + obj
    } else {
      return obj
    }
  }
  const year = new Date().getFullYear()
  const month = appendZero(new Date().getMonth() + 1)
  const day = appendZero(new Date().getDate())
  const week = new Date().getDay()
  const data = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  // 修改数据date
  todayDateValue =
    year + '年' + month + '月' + day + '日' + ' ' + data[week]
  return todayDateValue
}

export function getMonth (num:any) {
  if (num <= 0) return
  let today = null
  let year = null
  let month = null
  let numRemainder = null
  let needMonthNext = null
  let needMonth = null
  today = new Date()
  year = today.getFullYear() // 当前年份
  let needYear = year - Math.floor(num / 12) // 获取到想要查询月所在的年份
  // let needYearNext = needYear // 获取到想要查询月下一个月所在的年份
  month = today.getMonth() + 1 // 获取到当前月

  // 获取num 的余数  如果余数大于等于当前月份  则年份还要减1
  numRemainder = num % 12
  if (numRemainder >= month) {
    needYear--
    month += 12
  }

  // 获取到所需要的月份和所需要的月份的下一个月
  needMonth = month - numRemainder
  needMonthNext = needMonth + 1

  // 如果所需要月份的下一个月大于12 则需要调到下一年的一月
  if (needMonthNext > 12) {
    needMonthNext = 1
  }
  if (needMonth === 0) needMonth = 12
  // 所需月份小于10 则前面补0
  if (needMonth < 10) needMonth = '0' + needMonth
  if (needMonthNext < 10) needMonthNext = '0' + needMonthNext
  // console.log()
  const datatime = new Date(needYear, needMonth as any, 0).getDate()
  // console.log(datatime, '当月最后一天')
  return [`${needYear}-${needMonth}-01`, `${needYear}-${needMonth}-${datatime}`]
}

function doHandleMonth (month:any) {
  let m = month
  if (month.toString().length === 1) {
    m = '0' + month
  }
  return m
}

// 获取当天日期
export function getDay (day:any) {
  let today = new Date()
  const targetdayMilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetdayMilliseconds) // 注意，这行是关键代码
  const tYear = today.getFullYear()
  let tMonth = today.getMonth()
  let tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
}

// 获取本月数据
export function getCurrentMonth () {
  let today = null
  let year = null
  let month = null
  let datatime = null
  today = new Date()
  year = today.getFullYear() // 当前年份
  month = today.getMonth() + 1 // 获取到当前月
  let startTimeOne = null
  let endTimeOne = null
  let time = null
  datatime = new Date(year, month, 0).getDate()
  if (month < 10) {
    startTimeOne = `${year}-0${month}-01`
    endTimeOne = `${year}-0${month}-${datatime}`
  } else {
    startTimeOne = `${year}-${month}-01`
    endTimeOne = `${year}-${month}-${datatime}`
  }
  time = [startTimeOne, endTimeOne]
  return time
}
// 上传图片的请求头
export function getCookieToken (name:any) {
  const strcookie = document.cookie // 获取cookie字符串
  const arrcookie = strcookie.split('; ') // 分割
  // 遍历匹配
  for (let i = 0; i < arrcookie.length; i++) {
    const arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arr[1]
    }
  }
  return ''
}
