
import { defineComponent, h, KeepAlive, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'
export default defineComponent({
  components: {
    RouterView
  },
  setup () {
    const route = useRoute()
    // const flag = ref<boolean>(false)
    // if (route.meta.keepAlive) {
    //   flag.value = true
    // } else {
    //   flag.value = false
    // }
    // return {
    //   flag
    // }
  }
  // render () {
  //   return h(RouterView, {
  //   }, {
  //     default: ({ Component }: { Component: any }) => h({}, [h(Component)])
  //   })
  // }
})
